import { useState, useCallback } from "react";

import "./App.css";

import { PinaColada } from "./components/PinaColada";

const INITIAL_COUNT = 10;

interface Fruit {
  id: number;
  variant: "pina" | "colada";
  initialY?: number;
}

const generateInitialFruits = () => {
  let fruits: Fruit[] = [];
  for (let i = 1; i <= INITIAL_COUNT; i++) {
    fruits.push({
      id: i,
      variant: Math.random() >= 0.5 ? "pina" : "colada",
      initialY: Math.random() * 30,
    });
  }

  return fruits;
};

function App() {
  const [fruits, setFruits] = useState<Fruit[]>(generateInitialFruits);

  const queueNewFruit = useCallback((lastId: number) => {
    setFruits((current) => {
      return [
        ...current.filter(({ id }) => id !== lastId),
        {
          id: lastId + INITIAL_COUNT,
          variant: Math.random() >= 0.5 ? "pina" : "colada",
        },
      ];
    });
  }, []);

  const day = new Date().toLocaleDateString("en-US", { weekday: "long" });
  return (
    <div className="App">
      {fruits.map((f) => (
        <PinaColada
          variant={f.variant}
          id={f.id}
          key={f.id}
          initialY={f.initialY}
          onAnimationEnd={queueNewFruit}
        />
      ))}
      <p>It's {day}. Have a Piña colada!</p>
    </div>
  );
}

export default App;
