import { useCallback, FC, useRef } from "react";
import { motion } from "framer-motion";

import pina from "../images/pina.png";
import colada from "../images/colada.png";

interface PinaColadaProps {
  id: number;
  variant: "pina" | "colada";
  onAnimationEnd: (id: number) => void;
  initialY?: number;
}

export const PinaColada: FC<PinaColadaProps> = ({
  variant,
  onAnimationEnd,
  id,
  initialY = -20,
}) => {
  const fruit = variant === "pina" ? pina : colada;
  const onEnd = useCallback(() => onAnimationEnd(id), [onAnimationEnd, id]);

  const fallSpecs = useRef({
    duration: Math.random() * 3 + 4,
    initialX: Math.random() * 100,
  });

  const rotationSpecs = useRef({
    duration: fallSpecs.current.duration + Math.random(),
    initial: Math.random() * 180,
    end: Math.random() * 1440 - 720,
  });

  return (
    <motion.div
      style={{ position: "absolute" }}
      initial={{
        y: `${initialY}vh`,
        x: `${fallSpecs.current.initialX}vw`,
        scale: Math.random() * 0.5 + 0.5,
      }}
      animate={{ y: "105vh" }}
      transition={{ duration: fallSpecs.current.duration, ease: "linear" }}
      onAnimationComplete={onEnd}
    >
      <motion.img
        src={fruit}
        alt={variant === "pina" ? "🍍" : "🥥"}
        initial={{ rotate: rotationSpecs.current.initial }}
        animate={{ rotate: rotationSpecs.current.end }}
        transition={{
          duration: rotationSpecs.current.duration,
          repeat: Infinity,
          repeatType: "mirror",
          ease: "linear",
        }}
      />
    </motion.div>
  );
};
